<template>
	<div class="contain">
		<!-- 出勤-分公司-->
		<div class="form-header-btn">
			<div class="header-title">出勤列表</div>
			<div>
				<el-dropdown @command="handleCommand">
					<el-button type="primary" style="margin-right: 0.52083vw;">
						导出 <i class="el-icon-arrow-down el-icon--right"></i>
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="school">按园校</el-dropdown-item>
						<el-dropdown-item command="all">全部</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>

		<el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
			<el-form-item>
				<el-select v-model="form.parkId" clearable placeholder="请选择园校" @change="handlePark">
					<el-option v-for="item in moduleParkOptions" :key="item.label" :label="item.name"
						:value="item.label">
					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item>
				<el-select v-model="form.classId" clearable placeholder="请选择班级" :disabled="disabledClass">
					<el-option v-for="item in moduleClassOptions" :key="item.label" :label="item.name"
						:value="item.label">
					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item>
				<el-date-picker type="month" placeholder="请选择日期" v-model="form.attendanceDate" value-format="yyyy-MM"
					style="width: 100%" :picker-options="pickerOptions" :clearable="false"></el-date-picker>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" icon="el-icon-search" @click="searchFun">
					查询
				</el-button>
			</el-form-item>

			<el-form-item>
				<el-button icon="el-icon-refresh-left" @click="resetFun">
					重置
				</el-button>
			</el-form-item>
		</el-form>

		<div class="change-open-level">
			<el-radio-group v-model="openLevel" @change="changeOpenLevel">
				<el-radio :label="0">收起</el-radio>
				<!-- <el-radio :label="0">展开一级</el-radio>
        <el-radio :label="2">展开全部</el-radio> -->
			</el-radio-group>
		</div>

		<!-- 表格 -->
		<el-table :data="tableData" :header-cell-style="{
        background: '#F1F4F9',
        color: '#5E6D9B',
        fontWeight: '400',
        'text-align':'center'
      }" :summary-method="getSummaries" show-summary v-loading="tableLoading" :expand-row-keys="expandRow"
			ref="tableData" row-key="classId" :tree-props="{ children: 'attendanceList', hasChildren: 'hasChildren' }"
			@sort-change="sortChange" lazy :load="loadData" @expand-change="changeExpand">
			<el-table-column prop="className" label="园校" min-width="250" fixed="left">
			</el-table-column>
			<el-table-column prop="attendanceNumber" label="应出勤人次" min-width="120" fixed="left" align="center">
			</el-table-column>
			<el-table-column prop="attendanceRealNumber" label="实际出勤人次" min-width="120" fixed="left" align="center">
			</el-table-column>
			<el-table-column prop="attendanceRate" label="出勤率" min-width="100" fixed="left" align="center"
				sortable="custom">
				<template slot-scope="{ row }">
					<span v-if="row.attendanceRate">
						{{ row.attendanceRate + "%" }}
					</span>
				</template>
			</el-table-column>
			<!-- <template> -->
			<el-table-column v-for="(item, index) in attendanceDetailsList" :key="index" :label="item.label"
				:prop="item.prop" min-width="60" align="center">
				<template slot-scope="scope">
					<!-- 默认出勤数据 -->
					<span>
						{{ scope.row[item.prop] }}
					</span>
				</template>
			</el-table-column>
			<!-- </template> -->
		</el-table>
	</div>
</template>

<script>
	import downloadFile from "@/utils/downloadFile";
	import {
		getPermissionButton
	} from "@/utils/getUrlPermissionButtons";
	import {
		mapGetters,
		mapState
	} from "vuex";
	export default {
		data() {
			return {
				tableLoading: false, // 表格loading
				form: {
					parkId: "",
					classId: "",
					attendanceDate: "",
				}, //表单
				formExport: {
					parkId: "",
					classId: "",
					attendanceDate: "",
				},
				moduleClassOptions: [], // 所属班级下拉
				moduleParkOptions: [], //所属园校下拉
				tableData: [], //表格数据
				attendanceDetailsList: [], //月天数
				disabledClass: true, //是否禁用class选择框
				expandRow: [],
				openLevel: 1,
				permissionButtonList: [], // 权限按钮list
				isExport: false,
				pickerOptions: {
					disabledDate(times) {
						return times.getTime() > Date.now();
					},
				},
				nowYearMonth: "", //初始化时间
				resetDownDate: 0,
				advenSum: "",
			};
		},

		computed: {
			...mapState({
				menuList: (state) => state.common.menuList,
				userInfo: (state) => state.userInfo,
			}),
		},

		watch: {
			permissionButtonList: {
				handler(val) {
					if (val) {
						this.isExport = val.indexOf("f_attendance:export") != -1;
					}
				},
				deep: true,
			},
			menuList: {
				handler(val) {
					if (val) {
						this.getPermissionButton(this.$route.path, (data) => {
							this.permissionButtonList = data;
						});
					}
				},
				deep: true,
			},
		},

		created() {
			// menulist一直在时这个方法不可缺少
			this.getPermissionButton(this.$route.path, (data) => {
				this.permissionButtonList = data;
			});
		},

		mounted() {
			// this.tableLoading = true;
			this.getDateNow();
			// this.loadTableData();
			this.getCompanyDeptList();
			this.getRateByMonthForComSum();
		},
		activated() {
			this.tableLoading = true;
			this.loadTableData();
		},
		methods: {
			// 展开table时的初始化展开
			changeExpand() {
				this.openLevel = 1
			},
			// table懒加载
			loadData(tree, treeNode, resolve) {
				this.$api.getAttendanceInfoByMonthForHQ({
					orgId: tree.orgId,
					parkId: tree.classId,
					classId: '',
					attendanceDate: this.form.attendanceDate,
				}).then((res) => {
					if (res.data.code == "0") {
						this.initAttendanceTime(res.data.data[0].attendanceList[0].attendanceList)
						let obj = res.data.data[0].attendanceList[0].attendanceList
						resolve(obj)
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			// 表格数据初始化
			loadTableData() {
				console.log(this.$store.state.userInfo.schoolList)
				let orgId = ''
				if (this.$store.state.userInfo.schoolList.length > 0) {
					orgId = this.$store.state.userInfo.schoolList[0].companyLabel
				}
				let params = {
					orgId: orgId,
					parkId: this.form.parkId,
					classId: this.form.classId,
					attendanceDate: this.form.attendanceDate,
				};
				this.$api.getAttendanceInfoByMonthForHQComSchool(params).then(res => {
					if (res.data.code == 0) {
						this.initAttendanceTime(res.data.data[0].attendanceList)
						this.tableData = res.data.data[0].attendanceList
						res.data.data[0].attendanceList.map(item => {
							item.hasChildren = true
							item.orgId = orgId
						})
					}
				})
			},
			// 初始化出勤列表数据
			initAttendanceTime(attendanceList) {
				if (attendanceList && attendanceList.length > 0) {
					attendanceList.map(item => {
						if (item.attendanceDetailsList && item.attendanceDetailsList.length > 0) {
							item.attendanceDetailsList.map(itemChild => {
								item[`day${itemChild.attendanceTime}`] = itemChild.attendanceNumber;
							})
						}
					})
				}
			},
			//合计
			getRateByMonthForComSum() {
				let params = {
					parkId: this.form.parkId,
					classId: this.form.classId,
					attendanceDate: this.form.attendanceDate,
				};
				this.$api.getRateByMonthForComSum(params).then((res) => {
					if (res.data.code == "0") {
						this.tableLoading = false;
						this.advenSum = res.data.data;
					} else {
						this.tableLoading = false;
						this.$message.error(res.data.msg);
					}
				});
			},

			//排序
			sortChange(column) {
				// console.log(column);
				console.log(column.prop, column.order);
				// this.columnOrder = column.order;
				if (column.order == "descending") {
					this.init(column.prop, "2");
				} else if (column.order == "ascending") {
					this.init(column.prop, "1");
				}
			},

			getPermissionButton,
			//初始化获取当前月天数
			getDateNow(dateparams) {
				var date = dateparams ? new Date(dateparams) : new Date();
				var year = date.getFullYear();
				var month = date.getMonth() + 1;

				var dateReset = new Date();
				var yearReset = dateReset.getFullYear();
				var monthReset = dateReset.getMonth() + 1;

				this.nowYearMonth = `${yearReset}-${
        monthReset > 9 ? monthReset : "0" + monthReset
      }`;

				if (!dateparams) {
					this.form.attendanceDate = `${year}-${month > 9 ? month : "0" + month}`;
				}
				var d = new Date(year, month, 0);
				var dayCounts = d.getDate();
				var arr = [];
				for (var i = 0; i <= dayCounts - 1; i++) {
					var obj = {};
					obj.label = `${i + 1}日`;
					obj.prop = `day${i + 1}`;
					arr.push(obj);
				}
				this.attendanceDetailsList = arr;
			},

			/** 初始化 */
			init(sortName, order) {
				console.log(sortName, order);
				let params = {
					parkId: this.form.parkId,
					classId: this.form.classId,
					attendanceDate: this.form.attendanceDate,
				};

				if (sortName === "attendanceRate" && order == "2") {
					params.attendanceRateOrder = "2";
				} else if (sortName === "attendanceRate" && order == "1") {
					params.attendanceRateOrder = "1";
				}

				this.$api.getAttendanceInfoByMonthForCom(params).then((res) => {
					this.tableLoading = false;
					if (res.data.code == "0") {
						res.data.data.map((item) => {
							if (
								item.attendanceDetailsList &&
								item.attendanceDetailsList.length > 0
							) {
								item.attendanceDetailsList.map((child) => {
									item[`day${child.attendanceTime}`] = child.attendanceNumber;
								});
							}

							if (item.attendanceList && item.attendanceList.length > 0) {
								item.attendanceList.map((itemlist) => {
									if (
										itemlist.attendanceDetailsList &&
										itemlist.attendanceDetailsList.length > 0
									) {
										itemlist.attendanceDetailsList.map((child) => {
											itemlist[`day${child.attendanceTime}`] =
												child.attendanceNumber;
										});
									}
								});
							}
						});
						this.tableData = []
						this.tableData = res.data.data;
						setTimeout(() => {
							this.changeOpenLevel(4);
						}, 200);
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},

			//查询园校
			getCompanyDeptList() {
				let params = {
					type: "NODE_SCHOOL",
				};
				this.$api.getCompanyDept(params).then((res) => {
					this.tableLoading = false;
					if (res.data.code == "0") {
						this.moduleParkOptions = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},

			//查询园校change事件
			handlePark(val) {
				this.form.classId = "";
				if (val == "") {
					this.disabledClass = true;
				} else {
					this.disabledClass = false;
					this.getClassInfoList(val);
				}
			},

			//查询班级
			getClassInfoList(valPark) {
				let params = {
					parentLabel: valPark,
				};
				this.$api.getClassInfo(params).then((res) => {
					this.tableLoading = false;
					if (res.data.code == "0") {
						this.moduleClassOptions = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},

			//表尾合计
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];

				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = "合计";
						return;
					}

					const values = data.map((item) => Number(item[column.property]));

					// if (column.property === "isOverspend") {
					//   const resvue = values.includes(1);
					//   sums[index] = resvue == true ? "是" : "否";
					//   return;
					// }

					// if (column.property === "executionRatio") {
					//   const resvue = values.reduce((prev, curr) => {
					//     const value = Number(curr);
					//     if (!isNaN(value)) {
					//       return prev + curr;
					//     } else {
					//       return prev;
					//     }
					//   }, 0);

					//   const adven = (resvue / values.length).toFixed(2);
					//   sums[index] = adven + "%";
					//   return;
					// }

					if (index === 3) {
						// this.sums = this.advenSum + "%";
						sums[index] = this.advenSum + "%";
						return;
					}

					if (!values.every((value) => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
					}
				});
				this.sums = sums;
				return sums;
			},
			// 按类型导出
			handleCommand(type) {
				if (type == 'company') {
					this.downLoadFile(1)
				} else if (type == 'school') {
					this.downLoadFile(2)
				} else if (type == 'all') {
					this.downLoadFile(3)
				}
			},
			//导出
			downLoadFile(downFlag) {
				//下载文件
				let url = "/mng/attendance/attendance/export";
				let userInfo = sessionStorage.getItem("userInfo")
				  ? JSON.parse(sessionStorage.getItem("userInfo"))
				  : {};
				let data = {
					orgId: userInfo.schoolList[0].companyLabel,
					parkId: this.form.parkId,
					classId: this.form.classId,
					attendanceDate: this.resetDownDate == 1 ?
						this.nowYearMonth :
						this.form.attendanceDate,
					downFlag
				};

				let type = "get";

				this.tableLoading = true;
				downloadFile({
					url,
					data,
					type
				}).then(() => {
					this.tableLoading = false;
				});

				this.resetDownDate = 0;
			},

			// 查询
			searchFun() {
				this.tableLoading = true;
				this.getDateNow(this.form.attendanceDate);
				if (this.form.parkId !== '') {
					this.init();
				} else {
					this.loadTableData();
				}
				this.getRateByMonthForComSum();
				this.formExport = {
					classId: this.form.classId,
					parkId: this.form.parkId,
					attendanceDate: this.form.attendanceDate,
				};
			},

			// 重置
			resetFun() {
				this.form = {
					parkId: "",
					classId: "",
					attendanceDate: this.nowYearMonth,
				};
				this.resetDownDate = 1;
				this.disabledClass = true;
				this.loadTableData();
				this.resetTabDate();
			},

			//展开一级
			changeOpenLevel(val) {
				this.resetTabDate();
				this.openLevel = val
				if (val == 2) {
					this.expandRow = [];
					this.tableData.map((item) => {
						this.expandRow.push(item.classId);
						this.$refs.tableData.toggleRowExpansion(item, true);
					});
				} else if (val == 4) {
					this.expandRow = [];
					this.tableData.map((item) => {
						this.expandRow.push(item.classId);
						this.$refs.tableData.toggleRowExpansion(item, true);
					});
				}
			},

			//收起展开菜单
			resetTabDate(arr) {
				this.expandRow = [];
				let data = arr || this.tableData;
				data.forEach((item) => {
					this.$refs.tableData.toggleRowExpansion(item, false);
					if (
						Object.prototype.toString.call(item.attendanceList) ==
						"[object Array]"
					) {
						this.resetTabDate(item.attendanceList);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	@import "@/assets/css/select_list.scss";

	.contain {
		padding: 26px 30px 30px 30px;

		/deep/ .el-table__body-wrapper {
			// z-index: 2;
		}

		.form-header-btn {
			display: flex;
			justify-content: space-between;
			margin-bottom: 16px;

			.sheet-btn {
				background: #003685;
				box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
				opacity: 1;
				border-radius: 4px;
				font-size: 14px;
				color: #ffffff;
			}

			/deep/.el-button+.el-button {
				margin-left: 20px;
			}
		}

		/deep/.el-table {
			// margin-top: 14px;
		}

		/deep/.el-input__inner {
			// text-align: center;
		}

		/deep/.el-table th>.cell {
			padding-left: 14px;
		}

		.change-open-level {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 20px;
		}
	}
</style>
